<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
  >

    <b-card title="Forms">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search forms"
            type="text"
          />
          <!-- <b-button
            v-b-modal.knowledgeBaseModal
            variant="primary"
            class="mr-1"
          >
            <feather-icon icon="PlusIcon" />
            Add Resource
          </b-button> -->
          <b-button
            v-b-modal.ModuleModal
            variant="primary"
            @click="makeModalNew(); updateID = null; update = false;"
          >
            <feather-icon icon="PlusIcon" />
            Add Form
          </b-button>
        </b-form-group>
      </div>
      <!-- card -->
      <section
        v-if="view"
        class="grid-view mt-1"
      >
        <b-row v-if="modules.length===0">
          <b-col>
            <b-card
              tag="article"
              style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              class="ecommerce-card"
            >
              <h6 class="d-flex align-items-center justify-content-center  mb-0 text-center">
                No modules are available
              </h6>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col
            v-for="(row, i) in modules"
            :key="i"
            :title="row.title"
            md="4"
            style="margin-bottom: 10px"
          >
            <b-card
              :key="i"
              header-tag="header"
              tag="article"
              style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              class="ecommerce-card"
            >
              <template #header>
                <b-card-title class="d-flex align-items-center justify-content-center  mb-0 text-center">
                  {{ row.title }}
                </b-card-title>
                <div>
                  <b-badge
                    v-if="!props.row.is_default"
                    :variant="`${props.row.is_private ? 'primary' : 'secondary'}`"
                  >
                    <feather-icon :icon="`${props.row.is_private ? 'Lock' : 'Users'}Icon`" />
                    <span style="margin-left:4px">{{ props.row.is_private ? 'Private' : 'Public' }}</span>
                  </b-badge>
                  <b-badge
                    v-else
                    variant="outline-primary"
                  >
                    Default
                  </b-badge>
                </div>
              </template>
              <b-card-text>{{ row.description }}</b-card-text>
              <b-card-footer class="p-0">
                <div class="d-flex justify-content-between mt-2">
                  <div>
                    <b-button
                      size="sm"
                      variant="primary"
                      class="mr-50"
                      :to="{ name: 'forms-details', params: { fid: row.id, mname: row.title } }"
                    >Open</b-button>
                  </div>
                  <div>
                    <b-button
                      v-if="!props.row.is_default"
                      v-b-modal.ModuleModal
                      size="sm"
                      variant="flat-primary"
                      class="mr-50 px-0"
                      @click="update = true; moduletitle = row.title; moduledescription = row.description; is_private = row.is_private; selectedRow = row.id;"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      v-if="!props.row.is_default"
                      size="sm"
                      class="px-0"
                      variant="flat-danger"
                      @click="selectedRow = row.id; deleteModule(row.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </section>
      <!-- table -->
      <vue-good-table
        v-if="!view"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="modules"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- <span
            v-if="props.column.field === 'visibility'"
            class="d-flex flex-nowrap"
          >
            <b-badge
              v-if="!props.row.is_default"
              :variant="`${props.row.is_private ? 'primary' : 'secondary'}`"
            >
              <feather-icon :icon="`${props.row.is_private ? 'Lock' : 'Users'}Icon`" />
              <span style="margin-left:4px">{{ props.row.is_private ? 'Private' : 'Public' }}</span>
            </b-badge>
            <b-badge
              v-else
              variant="outline-primary"
            >
              Default
            </b-badge>
          </span> -->
          <span
            v-if="props.column.field==='submission'"
          >
            <div class="d-flex justify-content-center">
              <b-badge
                :variant="getBadgeVariant(props.row.status)"
                style="text-transform: capitalize"
              >
                {{ props.row.status }}
              </b-badge>
            </div>
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :id="'submission-' + props.row.id"
                :checked="props.row.status === 'enabled'"
                class="mt-50 mr-0"
                inline
                hide-labels
                switch
                @change="updateID = props.row.id; updateStatus(props.row.status)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </span>
          <span
            v-if="props.column.field==='evaluation'"
          >
            <div class="d-flex justify-content-center">
              <b-badge
                :variant="getBadgeVariant(props.row.review_status)"
                style="text-transform: capitalize"
              >
                {{ props.row.review_status }}
              </b-badge>
            </div>
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :id="'submission-' + props.row.id"
                :checked="props.row.review_status === 'enabled'"
                class="mt-50 mr-0"
                inline
                hide-labels
                switch
                @change="updateID = props.row.id; updateReviewStatus(props.row.review_status)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </span>
          <span
            v-if="props.column.field === 'action'"
            class="d-flex flex-nowrap"
          >
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50"
              :to="{ name: 'forms-responses', params: { fid: props.row.id, mname: props.row.title } }"
            >Responses</b-button>
            <b-button
              v-if="!props.row.is_default"
              size="sm"
              variant="outline-primary"
              class="mr-50"
              @click="copyToClipboard(`startup-portal/forms/responses/${props.row.id}/${props.row.title}/response`)"
            >
              Copy Link
            </b-button>
            <b-button
              v-if="!props.row.is_default"
              size="sm"
              variant="flat-danger"
              @click="selectedRow = props.row.id; deleteModule(props.row.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
          <!-- Column: Common -->
          <span
            v-else-if="props.column.field === 'title'"
            class="d-flex justify-content-between"
          >
            <span>{{ props.formattedRow[props.column.field] }}</span>
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50"
              @click="updateForm(props.row)"
            >{{ props.row.status === 'draft' ? 'Edit' : 'View' }}</b-button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20','30']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        id="ModuleModal"
        ok-title="Save"
        no-close-on-backdrop
        size="lg"
        title="Form"
        :footer-class="(status === 'draft' && updateID) || !updateID ? '' : 'd-none'"
        :ok-disabled="!formTitle && !formType && formDescription && !applicationForm"
        @ok="addForm"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Title"
              label-size="sm"
            >
              <b-form-input
                v-model="formTitle"
                placeholder="Form Title"
                :disabled="status !== 'draft' && updateID"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Type"
              label-size="sm"
            >
              <v-select
                v-model="formType"
                multiple
                :close-on-select="false"
                :disabled="status !== 'draft' && updateID"
                class="m-0 p-0"
                :options="typeOptions"
                :get-option-label="option => option.title"
                :reduce="option => option.value"
                placeholder="Select Type"
              >
                <template #list-footer>
                  <b-button
                    class="m-50"
                    size="sm"
                    variant="primary"
                    @click="formType = [...typeOptions].map(i => i.value)"
                  >
                    Select All
                  </b-button>
                  <b-button
                    class="m-50"
                    size="sm"
                    variant="outline-danger"
                    @click="formType = []"
                  >
                    Clear All
                  </b-button>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Description (Description will be visible for responders filling out this form)"
              label-size="sm"
            >
              <b-form-input
                v-model="formDescription"
                :disabled="status !== 'draft' && updateID"
                placeholder="Form Description"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <form-builder
          v-model="applicationForm"
          :inherited-sections="applicationForm"
          :is-disabled="status !== 'draft' && updateID"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>
<script>

import {
  BBadge,
  BCard,
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  // BInputGroup,
  // BFormTextarea,
  BCardTitle,
  BFormSelect,
  BCardFooter,
  // BLink,
  BCardText,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import { required, size } from '@core/utils/validations/validations'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BButton,
    VueGoodTable,
    BPagination,
    BCardFooter,
    vSelect,
    BCardText,
    BFormSelect,
    BBadge,
    FormBuilder,
    BFormCheckbox,
  },
  data() {
    return {
      view: false,
      updateID: null,
      formTitle: null,
      formType: null,
      formDescription: null,
      applicationForm: null,
      mutationLoading: false,
      update: false,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      typeOptions: [
        {
          title: 'Incubatee',
          value: 'incubatee',
        },
        {
          title: 'Pre-Incubatee',
          value: 'pre-incubatee',
        },
        {
          title: 'Network',
          value: 'startup',
        },
        {
          title: 'Graduate',
          value: 'graduate',
        },
      ],
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        // {
        //   label: 'Visibility',
        //   field: 'visibility',
        // },
        {
          label: 'Submission',
          field: 'submission',
        },
        // {
        //   label: 'Evaluation',
        //   field: 'evaluation',
        // },
        {
          label: 'Date Created',
          field(row) {
            if (!row.created_at) return '-'
            return `${new Date(row.created_at)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: this.timezone.abbr,
                hour12: true,
              })}`
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      modules: [],
      operations: [],
      is_internal: true,
      is_private: true,
      file_link: null,
      file: null,
      recommendationLevel: null,
      title: null,
      description: '',
      status: '',
      moduletitle: null,
      moduledescription: '',
      selectedOperation: null,
      required,
      size,
    }
  },
  computed: {
    ...mapGetters({
      timezone: 'user/getUserTimezone',
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
  },
  methods: {
    getBadgeVariant(status) {
      if (status === 'enabled') {
        return 'light-primary'
      }
      if (status === 'disabled') {
        return 'light-danger'
      }
      return 'light-secondary'
    },
    copyToClipboard(text) {
      const newtext = `${window.location.origin}/${text}`
      if (navigator.clipboard) {
        navigator.clipboard.writeText(newtext).then(() => {
          this.showAlert(
            'Link copied to clipboard',
            'success',
          )
        }).catch(() => {
        })
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = newtext
        textarea.style.position = 'fixed' // ensure the textarea is rendered offscreen
        document.body.appendChild(textarea)
        textarea.focus()
        textarea.select()
        try {
          document.execCommand('copy') // execute the copy command
        } catch (err) {
          // failed to copy
        } finally {
          document.body.removeChild(textarea) // remove the textarea from the DOM
        }
      }
    },
    getEditButtonVariant(submission) {
      return submission ? 'outline-secondary' : 'outline-primary'
    },
    isEditDisabled(row) {
      return row.submission || row.evaluation
    },
    updateView(view) {
      this.view = view
    },
    updateForm(data) {
      this.updateID = data.id
      this.formTitle = data.title
      this.formType = data.type.replace(' ', '').split(',')
      this.status = data.status
      this.formDescription = data.description
      this.applicationForm = data.programs_applicationsquestionstables
      this.$bvModal.show('ModuleModal')
    },
    makeModalNew() {
      this.formTitle = ''
      this.formType = ''
      this.status = ''
      this.formDescription = ''
      this.applicationForm = null
    },
    async updateStatus(status) {
      this.mutationLoading = true
      await this.$apollo.mutate(
        {
          mutation: gql`mutation UpdateStatus($id: Int!, $status: String!) {
                        update_programs_applicationformstable_by_pk(pk_columns: {id: $id }, _set: {status: $status}) {
                          id
                        }
                      }`,
          variables() {
            return {
              id: this.updateID,
              status: (status === 'draft' || status === 'disabled') ? 'enabled' : 'disabled',
            }
          },
          update: () => {
            this.mutationLoading = false
            this.showAlert(
              'Status updated successfully',
              'success',
            )
            this.$apollo.queries.modules.refetch()
          },
        },
      ).catch(() => {
        this.mutationLoading = false
        this.showAlert(
          'An error occurred',
          'danger',
        )
      }).finally(() => {
        this.mutationLoading = false
      })
    },
    async updateReviewStatus(status) {
      this.mutationLoading = true
      await this.$apollo.mutate(
        {
          mutation: gql`mutation UpdateReviewStatus($id: Int!, $status: String!) {
                        update_programs_applicationformstable_by_pk(pk_columns: {id: $id }, _set: {review_status: $status}) {
                          id
                        }
                      }`,
          variables() {
            return {
              id: this.updateID,
              status: (status === 'draft' || status === 'disabled') ? 'enabled' : 'disabled',
            }
          },
          update: () => {
            this.mutationLoading = false
            this.showAlert(
              'Review status updated successfully',
              'success',
            )
            this.$apollo.queries.modules.refetch()
          },
        },
      ).catch(() => {
        this.mutationLoading = false
        this.showAlert(
          'An error occurred',
          'danger',
        )
      }).finally(() => {
        this.mutationLoading = false
      })
    },
    getAllTypes() {
      let str = ''
      this.formType.map(type => {
        if (str === '') {
          str = type
        } else {
          str = `${str}, ${type}`
        }
        return 0
      })
      return str
    },
    addForm() {
      let payload = {
        title: this.formTitle,
        type: this.getAllTypes(),
        description: this.formDescription,
        is_form: true,
        status: 'draft',
        review_status: 'draft',
        incubator_id: this.selectedOrg.orgId,
        programs_applicationsquestionstables: {
          data: this.applicationForm.map(ques => ({
            ...ques,
            options: JSON.stringify(ques.options),
          })),
          on_conflict: {
            constraint: 'programs_applicationsquestionstable_pkey',
            update_columns: ['question', 'input_type', 'options', 'section', 'is_required'],
          },
        },
      }
      if (this.updateID) {
        payload = {
          ...payload,
          id: this.updateID,
        }
      }
      this.$apollo.mutate(({
        mutation: gql`mutation($object: programs_applicationformstable_insert_input!) {
            insert_programs_applicationformstable_one(object: $object, on_conflict: {constraint: programs_applicationformstable_pkey, update_columns: [title, description, type]}) {
              id
            }
          }`,
        variables() {
          return {
            object: payload,
          }
        },
        update: () => {
          this.$apollo.queries.modules.refetch()
          this.mutationLoading = false
        },
      }))
    },
  },
  apollo: {
    modules: {
      query() {
        return gql`
        query applications($subdomain: String!, $incubator_id: Int!) {
          programs_applicationformstable(where: { incubator_id: {_eq: $incubator_id}, users_organizationtable: {domain: {_regex: $subdomain}}, is_form: {_eq: true}}, order_by: {id: desc}) {
            id
            description
            created_at
            review_status
            users_organizationtable {
              id
              domain
            }
            status
            title
            type
            programs_applicationsquestionstables(order_by: {id: asc}) {
              id
              input_type
              is_required
              options
              question
              section
            }
          }
        }`
      },
      variables() {
        return {
          subdomain: this.getIncubatorFromSubdomain() || '.*',
          incubator_id: this.selectedOrg.orgId,
        }
      },
      update: data => data.programs_applicationformstable,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
